import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import { MaterialSymbolsCheckCircleOutlineRounded } from '@/assets/icons/comm/MaterialSymbolsCheckCircleOutlineRounded';
import { BasilUserSolid } from '@/assets/icons/guide/BasilUserSolid';
import BaseConfirmModal from '@/components/base/baseConfirmModal';
import { BaseLoadingDisplay } from '@/components/base/baseLoadingDisplay';
import PageEnum from '@/enums/pageEnum';
import {
  createVipOrder,
  getVipInfoApi,
  getvipLevelConfig,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import RenderUtil from '@/utils/RenderUtil';
import { Button, RadioGroup, cn, useDisclosure } from '@nextui-org/react';
import { history, useModel } from '@umijs/max';
import { useMount } from 'ahooks';
import { Key, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';
import BaseTopNav from '../../../components/base/baseTopNav';
import { CustomRadio } from './components/CustomRadio';

export default () => {
  const { t } = useTranslation();

  // 控制密码是否可见
  const [selected, setSelected] = useState('');

  // vip配置
  const { data: vipInfo = {} as any, run: getVipInfoReq } = useReq(
    getVipInfoApi,
    {
      manual: true,
    },
  );

  const {
    data: list = [] as any,
    loading,
    refreshAsync: getList,
  } = useReq(getvipLevelConfig, {
    loadingDefault: true,
    formatResult: (res: any) => {
      return res?.data ?? [];
    },
  });

  //当前订阅 对象
  const thisSub = useMemo(() => {
    if (vipInfo?.level && list?.length) {
      return list.find((item: any) => {
        return vipInfo?.level === item?.level;
      });
    }
    return {};
  }, [list, vipInfo]);

  const selectedObj = useMemo(() => {
    if (selected && list?.length) {
      return list.find((item: any) => {
        return selected === item?.id;
      });
    }
    return {};
  }, [list, selected]);

  useEffect(() => {
    if (!selected && list?.length) {
      setSelected(list[0]?.id);
    }
  }, [list]);

  // 获取单位
  const getTimeUnit = (time: number) => {
    // if (time < 30) {
    //   return { dayNum: time, unit: t('天') };
    // } else if (time < 90) {
    //   return { dayNum: time / 30, unit: t('月') };
    // } else if (time < 365) {
    //   return { dayNum: time / 90, unit: t('季度') };
    // } else {
    //   return { dayNum: time / 365, unit: t('年') };
    // }
    return { dayNum: time, unit: t('天') };
  };

  const { user } = useModel('user');
  useMount(() => {
    getVipInfoReq();
  });

  const vipModelRef = useDisclosure();

  // 申请订阅
  const { runAsync: onSubmitReq, loading: btnLoading } = useReq(
    () => createVipOrder({ configId: selected }),
    {
      loadingDefault: false,
      manual: true,
      onSuccess: (res: any) => {
        getVipInfoReq();
        toast.success(t('订阅成功'));
      },
      onError(e: any) {
        if (e?.resCode && e.resCode.toString().startsWith('100600')) {
          vipModelRef.onOpen();
        }
      },
    },
  );

  const funList = [
    {
      text: t('高杠杆合约'),
      value: '5',
    },
    // {
    //   text: t('币币订单'),
    //   value: '1',
    // },
    // {
    //   text: t('大宗订单'),
    //   value: '2',
    // },
    // {
    //   text: t('申购订单'),
    //   value: '3',
    // },
    // {
    //   text: t('股票订单'),
    //   value: '4',
    // },
  ];

  const isSub = !!vipInfo?.expireTime;

  // 渲染权限图标
  const renderPermissionIcon = (
    permissionValues: string | any[],
    funValue: string,
  ) => {
    return permissionValues.includes(funValue) ? (
      <MaterialSymbolsCheckCircleOutlineRounded
        className="text-warning"
        fontSize={20}
      />
    ) : (
      '--'
    );
  };

  return (
    <div
      className="text-foreground pb-4 flex flex-col"
      style={{
        backgroundImage: `url('${require('@/assets/img/vip/bg_vip.png')}')`,
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        color: '#fff',
      }}
    >
      <div className="flex-1 overflow-y-auto">
        <BaseTopNav title={t('订阅')} />

        <BaseLoadingDisplay loading={loading} list={list}>
          <div>
            <div className="px-4">
              <div className="flex justify-between mt-2">
                <div className="flex items-center">
                  {user?.avatar ? (
                    <img
                      className="w-[30px] h-[30px] rounded-full"
                      src={user?.avatar}
                    />
                  ) : (
                    <BasilUserSolid className="text-[38px]" />
                  )}

                  <div className="flex flex-col ml-2">
                    <span className="font-bold text-xl flex items-center">
                      {user?.name}
                      {isSub ? (
                        <span className="flex items-end">
                          <img
                            width={20}
                            height={10}
                            src={require('@/assets/img/vip/vip.png')}
                            className="ml-2"
                          />

                          <span className="text-xs ml-1 text-auxiliaryTextColor">
                            {thisSub?.name}
                          </span>
                        </span>
                      ) : (
                        <span className="flex items-end">
                          <img
                            width={20}
                            height={10}
                            src={require('@/assets/img/vip/vip0.png')}
                            className="ml-2"
                          />

                          <span className="text-xs ml-1 text-auxiliaryTextColor">
                            {t('普通用户')}
                          </span>
                        </span>
                      )}
                    </span>
                    {vipInfo?.expireTime && (
                      <span className="text-xs text-auxiliaryTextColor">
                        {t('有效期至')}{' '}
                        {RenderUtil.formatDate(vipInfo?.expireTime,"YYYY-MM-DD HH:mm:ss")}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {/*  描述 列表 */}
              <div className="bg-[#fff]/5 backdrop-blur-lg rounded-xl p-4 mt-4 overflow-x-auto mb-4">
                <div className="my-2 flex justify-between text-xs flex-nowrap">
                  <div
                    className="w-[80px] flex-shrink-0 text-auxiliaryTextColor"
                    style={{ flexBasis: 80 }}
                  >
                    {t('权益')}
                  </div>
                  {list.map((item: any) => (
                    <div
                      key={item?.id}
                      className={cn('w-[80px] flex-shrink-0')}
                    >
                      {item?.name}
                    </div>
                  ))}
                </div>

                {funList.map((item) => (
                  <div
                    key={item?.value}
                    className="flex justify-between flex-nowrap"
                  >
                    <div className="flex-shrink-0 w-[80px]  auxiliaryText py-2 ">
                      {item?.text}
                    </div>
                    {list.map?.(
                      (vipItem: {
                        permissions: string;
                        id: Key | null | undefined;
                      }) => {
                        const permissions = vipItem?.permissions.split(',');
                        return (
                          <div
                            key={vipItem?.id}
                            className="w-[80px] flex-shrink-0 auxiliaryText py-2"
                          >
                            {renderPermissionIcon(permissions, item.value)}
                          </div>
                        );
                      },
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* <div
              onClick={() => {
                vipModelRef.onOpen();
              }}
            >
              {t('测试弹框')}
            </div> */}

            <BaseConfirmModal
              isOpen={vipModelRef.isOpen}
              onClose={vipModelRef.onClose}
              okText={t('去订阅')}
              onOk={() => {
                vipModelRef.onClose();
              }}
              footer={
                <div className="mt-[24px] gap-[12px]">
                  <div
                    onClick={() => {
                      vipModelRef.onClose();
                      history.push(PageEnum.TopUp);
                    }}
                    className="flex py-2 justify-center items-center rounded-[8px] text-[16px] bg-primary text-white"
                  >
                    {t('充值')}
                  </div>

                  <div
                    onClick={() => {
                      vipModelRef.onClose();
                      history.push(PageEnum.TRANSFER);
                    }}
                    className="mt-4 py-2 flex justify-center items-center rounded-[8px] text-[16px] bg-primary/20 text-primary"
                  >
                    {t('从其他账户划转')}
                  </div>

                  <div
                    onClick={() => {
                      vipModelRef.onClose();
                    }}
                    className="mt-4 py-2 flex justify-center items-center rounded-[8px] text-[16px] bg-backgroundAuxiliaryColor"
                  >
                    {t('取消')}
                  </div>
                </div>
              }
            >
              <div className=" text-black">
                <div>{t('提示')}</div>
                <div className="mt-1">
                  {t('购买会员需要花费') +
                    ' ' +
                    selectedObj?.price +
                    ' ' +
                    selectedObj?.coinName +
                    ' ' +
                    t('当前您的账户') +
                    selectedObj?.coinName +
                    t('可用余额不足。')}
                </div>

                <div className="pt-3 text-auxiliaryTextColor text-xs"></div>
              </div>
            </BaseConfirmModal>

            <div className="mt-6 pb-[65px] bg-white text-[#000] px-4 rounded-tl-[18px] rounded-tr-[18px] absolute bottom-0 left-0 h-[50%] overflow-y-scroll w-full">
              <>
                <div className="text-base font-bold pt-4 pb-4">
                  {t('选择套餐')}
                </div>
                <RadioGroup
                  value={selected}
                  onChange={(e) => {
                    setSelected(e.target.value);
                  }}
                >
                  {list.map((item: any) => {
                    const isDisabled = item?.level < vipInfo?.level;
                    return (
                      <CustomRadio
                        isDisabled={isDisabled}
                        key={item?.id}
                        value={item?.id}
                      >
                        <div
                          className={`flex items-center pb-2 w-full ${
                            isDisabled ? 'opacity-50' : ''
                          }`}
                        >
                          <div className="flex justify-between pt-2 items-center">
                            <div className="font-bold">
                              <div className=" inline-block   pl-[8px] py-[1.5px] rounded-md clip-diagonal-reverse pr-6 text-[#000]">
                                {item?.name}
                                <div className="text-xs text-auxiliaryTextColor">
                                  {RenderUtil.FormatAmount(item?.price, 2)}
                                  {item?.coinName}{' '}
                                  <span className="ml-1">
                                    {`${item?.cycle}/${
                                      getTimeUnit(item?.cycle)?.unit
                                    }`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="text-[#868d9a] pt-4 text-xs">
                            {item?.shortDescription}
                          </div>
                        </div>
                      </CustomRadio>
                    );
                  })}
                </RadioGroup>
              </>

              <div className="flex-shrink-0 fixed bottom-0 left-0 h-[80px] w-full flex justify-center items-center px-4">
                <Button
                  onClick={onSubmitReq}
                  spinner={<LoadingSvg />}
                  isLoading={btnLoading}
                  className="mainColorButton !rounded-md h-[50px] !py-0 w-full mt-3"
                  color="primary"
                  disabled={
                    list?.find((item: any) => item.id === selected)?.level <
                    vipInfo?.level
                  }
                >
                  {selected &&
                  list?.find((item: any) => item.id === selected)?.level ===
                    vipInfo?.level
                    ? t('立即续费')
                    : t('立即订阅')}
                </Button>
              </div>
            </div>
          </div>
        </BaseLoadingDisplay>
      </div>
    </div>
  );
};

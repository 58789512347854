import { Radio, cn } from '@nextui-org/react';

export const CustomRadio = (props: any) => {
  const { children, ...otherProps } = props;

  return (
    <Radio
      {...otherProps}
      classNames={{
        base: cn(
          'inline-flex m-0 bg-content1  items-center justify-between bg-[#f7f7f7]',
          'flex-row-reverse max-w-[100%] cursor-pointer rounded-lg gap-4 p-4 border-1 border-[#E5E6EB]',
          'data-[selected=true]:border-primary',
        ),
      }}
    >
      {children}
    </Radio>
  );
};
